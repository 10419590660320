import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import { chunk }   from 'lodash';

import * as S from './styles';

import ItemCard      from '../../components/ItemCard';
import Wrapper       from '../../components/Wrapper';
import DefaultLayout from '../../layouts/Default';
import { findImage } from '../../components/_helpers';
import Spacer        from '../../components/Spacer';
import Heading       from '../../components/Heading';
import BrandsBand    from '../../components/BrandsBand';
import Separator     from '../../components/Seperator';
import { J }         from '../../components/Text';
import HeaderImage   from '../../components/HeaderImage';
import Button        from '../../components/Button';

const ITEMS_TO_SHOW = 12;
const RELOAD_OFFSET = 400;

class Items extends React.Component {

  constructor(props) {
    super(props);
    let itemsToShow = ITEMS_TO_SHOW;

    this.state = {
      showingMore: itemsToShow > ITEMS_TO_SHOW,
      itemsToShow,
      allItemsCount: this.props.data.items.edges.length
    }
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight);
    if (this.state.showingMore && distanceToBottom < RELOAD_OFFSET) {
      this.setState({ itemsToShow: this.state.itemsToShow + ITEMS_TO_SHOW })
    }
    this.ticking = false
  };

  handleScroll = () => {
    if (!this.ticking && this.state.itemsToShow <= this.state.allItemsCount) {
      this.ticking = true;
      requestAnimationFrame(() => this.update())
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  render() {
    const items = this.props.data.items.edges;
    const materialText = this.props.data.materialTexts.edges[0].node.texts.find(materialText => materialText.identifier === items[0].node.fields.subCategoryPath.split('/')[2]);
    return (
      <DefaultLayout location={this.props.location}>
        <HeaderImage>
          <Img
            fluid={findImage(this.props.data.headerImages, this.props.data.items.edges[0].node.fields.subCategoryPath.split('/')[2]
            ).fluid} alt={'Wedding Rings'}/>
        </HeaderImage>
        <Wrapper>
          <Spacer/>
          <Heading>{materialText.name}</Heading>
          {materialText && <J>{materialText.text}</J>}
          <S.Grid>
            {chunk(items.slice(0, this.state.itemsToShow), 4).map((chunk, i) => (
              <React.Fragment key={`chunk-${i}`}>
                {chunk.map(item => (
                  <ItemCard key={item.node.uid}
                            item={item.node}
                            itemImage={findImage(this.props.data.itemImages, item.node.itemNumber.toLowerCase()).fluid}/>
                ))}
              </React.Fragment>
            ))}
          </S.Grid>
          {!this.state.showingMore && items.length > ITEMS_TO_SHOW && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button onClick={() => {
                this.setState({
                  itemsToShow: this.state.itemsToShow + ITEMS_TO_SHOW,
                  showingMore: true,
                })
              }}>Mehr anzeigen</Button>
            </div>
          )}
          <Separator/>
          <h2 style={{ textAlign: 'center' }}>Unsere Trauring-Marken</h2>
          <BrandsBand brands={this.props.data.brands.edges}/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default Items;

export const query = graphql`
    query ItemsOverviewQuery($subCategory: String!) {
        items: allItemsXlsxTrauringe(filter: {subCategory: {eq: $subCategory}}) {
            edges {
                node {
                    uid
                    category
                    subCategory
                    manufacturer
                    diamond
                    price
                    itemNumber
                    fields {
                        slug
                        categoryPath
                        subCategoryPath
                        manufacturerPath
                        fullPath
                    }
                }
            }
        }
        itemImages: allFile(filter: {relativeDirectory: {regex: "/items/*/"}}) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 215, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        headerImages: allFile(filter: {relativeDirectory: {eq: "headers/wedding-rings"}}) {
            edges {
                node {
                    ...HeaderImages
                }
            }
        }
        brands: allMarkdownRemark(filter: {frontmatter: {type: {eq: "brand"}}}, sort: {fields: [frontmatter___slug], order: ASC}) {
            edges {
                node {
                    frontmatter {
                        logoPath
                        slug
                        title
                    }
                }
            }
        }
        materialTexts: allDataJson {
            edges {
                node {
                    texts {
                        name
                        identifier
                        text
                    }
                }
            }
        }
    }
`;
