import styled    from 'styled-components';
import PropTypes from 'prop-types';

const Separator = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  margin: ${({ spacing }) => spacing}px 0;
  display: inline-block;
`;

Separator.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  spacing: PropTypes.number,
};

Separator.defaultProps = {
  color: '#efefef',
  height: 1,
  spacing: 40,
};

export default Separator;
