import styled from 'styled-components/';

export const Grid = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  //IE Fallback
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
