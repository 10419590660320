export function urlalizer(path) {
  return `//${path}`;
}

export function insertSubmenus(menu, subMenus, insertKey) {
  let subMenu = [];
  subMenus.forEach((item) => {
    const menuItem = {};
    menuItem.displayName = item.edges[0].node.subCategory;
    menuItem.path = item.edges[0].node.fields.subCategoryPath;
    subMenu.push(menuItem);
  });
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].displayName === insertKey) {
      menu[i].sub = subMenu
    }
  }
  return menu;
}

export function findImage(images, imageName) {
  try {
    return images.edges.find(image => image.node.name.toLowerCase().trim() === imageName.toLowerCase().trim()).node.childImageSharp;
  } catch (e) {
    console.log(e);
    console.log('---------------------------------');
    console.log('Failed at image: ', imageName);
    console.log('---------------------------------');
  }
}