import React     from 'react';
import styled    from 'styled-components';
import PropTypes from 'prop-types';
import { Link }  from 'gatsby';

import { urlalizer } from '../_helpers';

export const BrandLinks = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  li {
    margin: 10px 10px 0 10px;
    a {
      line-height: normal;
      transition: opacity 0.2s ease;
      border: 1px solid black;
      opacity: 0.4;
      width: ${props => props.width};
      display: block;
      &.active {
        opacity: 1;
      }
    }
    img {
      padding: 8px 5px;
      margin-bottom: 0;
    }
    &:hover {
      a {
        opacity: 1;
      }
    }
  }
`;

function BrandsBand(props) {
  return (
    <nav>
      <BrandLinks width={props.iconWidth}>
        {props.brands.map((brand) => (
          <li key={brand.node.frontmatter.slug}>
            <Link activeClassName='active' to={`${brand.node.frontmatter.slug}`}>
              <img src={urlalizer(brand.node.frontmatter.logoPath)} alt={brand.node.frontmatter.title}/>
            </Link>
          </li>
        ))}
      </BrandLinks>
    </nav>
  )
}

BrandsBand.propTypes = {
  brands: PropTypes.array.isRequired,
  iconWidth: PropTypes.string,
};

BrandsBand.defaultProps = {
  iconWidth: '150px',
};

export default BrandsBand;
