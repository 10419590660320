import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => props.theme.colors.brandColor};
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 2px;
  text-transform: uppercase;
  transition: background-color 0.2s ease;
  padding: 8px 18px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  &:hover {
    background-color: #333333;
  }
`;

export default Button;