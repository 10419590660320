import React     from 'react';
import PropTypes from 'prop-types';
import Img       from 'gatsby-image';

import * as S from './styles';

let touched = false;

class ItemCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
    }
  }

  static propTypes = {
    item: PropTypes.object,
  };

  render() {
    const item = this.props.item;
    const alt = `${item.manufacturer} ${item.itemNumber}`;
    return (
      <S.StyledLink
        to={`${item.fields.fullPath}`}
        state={{ isModal: true }}
        onTouchStart={() => (touched = true)}
        onMouseEnter={() => {
          if (!touched) {
            this.setState({ hovering: true })
          }
        }}
        onMouseLeave={() => {
          if (!touched) {
            this.setState({ hovering: false })
          }
        }}
      >
        <S.Card>
          <S.Overlay visibility={this.state.hovering ? 'visible' : 'hidden'}>
            <ul>
              <li>{item.manufacturer}</li>
              <li>{item.diamond}</li>
              {parseInt(item.price) !== 0 && <li>{item.price} €</li>}
            </ul>
          </S.Overlay>
          <Img fluid={this.props.itemImage} alt={alt} title={alt}/>
        </S.Card>
      </S.StyledLink>
    )
  }
}

export default ItemCard;
