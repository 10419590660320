import styled   from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  outline: none;
`;

export const Card = styled.article`
  width: 250px;
  height: 250px;
  margin: auto;
  position: relative;
  img {
    transition: width 0.1s ease, height 0.1s ease !important;
    width: 80% !important;
    height: 80% !important;
    margin: auto;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  &:hover {
    img {
      width: 85% !important;
      height: 85% !important;
    }
  }
`;


export const Overlay = styled.div`
  transition: opacity 0.2s;
  opacity: ${({visibility}) => visibility === 'hidden' ? 0 : 1};
  position: absolute;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 15px rgba(0,0,0,0.6);
  background-color: rgba(0,0,0, 0.2);
  ul {
    list-style-type: none;
    margin: 0;
    li {
      margin: 0;
    }
  }
`;
